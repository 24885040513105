$(document).ready ->
  $('#caml-embedded-subscribe').on "click", ->
    $.post(
      $(this).data('action')
      email:$('#caml_email').val()
    )
    .done () ->
      $('#caml-error-response').hide();
      $('#caml-success-response').show();
      $('#caml_email').val('');
      return
    .fail (data) ->
      $('#caml-success-response').hide();
      $('#caml-error-response').text(data.responseJSON.error.message);
      $('#caml-error-response').show();
      return
    return
  return # end document ready